import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import FormFieldNumber from "../../../../components/forms/form-field-number.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import OutlinedSelectTextField from "../../../../components/forms/outlined-select-text-field.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";
import CustomizedSnackbars from "../../../../components/notification/snackbar.component";
import TableHeader from "../../../../components/tables/table-header.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import {
  updateMerchant,
  uploadMerchantLogo,
} from "../../../../services/merchant/merchant-slice.service";
import MerchantLogoUpload from "./merchant-logo-upload.component";
import RejectedFormField from "./rejected-form-field.component";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .nullable()
    .when("status", {
      is: "pending",
      then: Yup.string().email().nullable().required("Required when status is pending"),
    })
    .when("status", {
      is: "approved",
      then: Yup.string().email().nullable().required("Required when status is approved"),
    })
    .label("Email"),
  password: Yup.string().nullable().min(4).label("Password"),
  phone: Yup.string()
    .nullable()
    .notRequired()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .label("Phone Number"),
  businessName: Yup.string().label("Business Name"),
  firstName: Yup.string().nullable().label("First Name"),
  lastName: Yup.string().nullable().label("Last Name"),
  status: Yup.string().nullable().required().label("Status"),
  rejectReason: Yup.string()
    .nullable()
    .when("status", {
      is: "rejected",
      then: Yup.string().required(),
    })
    .label("Reject Reason"),
  commissions: Yup.array()
    .of(
      Yup.object().shape({
        chargeType: Yup.string().required().label("Charge Type"),
        chargeAmount: Yup.number()
          .required()
          .when("chargeType", {
            is: "percentage",
            then: (schema) =>
              schema.min(0.01, "Must be at least 0.01").max(100, "Must be at most 100"),
            otherwise: (schema) => schema.min(0.01, "Must be at least 0.01"),
          })
          .label("Charge Amount"),
      }),
    )
    .min(1, "At least one platform commission is required")
    .label("Commission"),
});

const validationSchemaLogo = Yup.object().shape({
  image: Yup.string().nullable().label("Logo"),
});

function MerchantDetails({ record }) {
  const location = useLocation();
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowEditImage, setAllowEditImage] = useState(false);
  const [filePreview, setFilePreview] = useState(location.state?.logo || record.logo);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const dispatch = useDispatch();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const onHandleSubmit = (values) => {
    const {
      merchantId,
      businessName,
      firstName,
      lastName,
      bankName,
      accountNo,
      accountHolderName,
      phone,
      email,
      status,
      password,
      rejectReason,
      commissions,
    } = values;
    if (values.status !== "rejected") {
      values.rejectReason = "";
    }

    dispatch(
      updateMerchant({
        merchantId,
        businessName,
        firstName,
        lastName,
        bankName,
        accountNo,
        accountHolderName,
        phone,
        email,
        status,
        password,
        rejectReason,
        commissions,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });

    setAllowEdit(false);
  };

  const onHandleSubmitLogo = (values) => {
    const { merchantId, image } = values;
    if (values.status !== "rejected") {
      values.rejectReason = "";
    }

    dispatch(uploadMerchantLogo({ image, merchantId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setAllowEditImage(false);
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
    });
  };

  return (
    <>
      <PaddedView>
        <CustomizedSnackbars
          isShow={isShowSnackBar}
          setShowSnackbar={setIsShowSnackBar}
          message={snackBarMsg}
          type={snackBarType}
        />
        <Formik
          validationSchema={validationSchemaLogo}
          onSubmit={onHandleSubmitLogo}
          initialValues={{
            merchantId: record.id,
            image: record.logo,
          }}
        >
          {({ handleSubmit, dirty }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Logo</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEditImage ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEditImage(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEditImage(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <MerchantLogoUpload
                    name="image"
                    setAllowEdit={setAllowEditImage}
                    showSuccessSnackBar={showSuccessSnackBar}
                    filePreview={filePreview}
                    setFilePreview={setFilePreview}
                    disabled={!allowEditImage}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>

        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandleSubmit}
          initialValues={{
            merchantId: record.id,
            businessName: record.name,
            firstName: record.firstName,
            lastName: record.lastName,
            phone: record.phoneNo,
            email: record.email,
            status: record.status,
            rejectReason:
              record.rejections?.length > 0
                ? record.rejections[record.rejections.length - 1].reason
                : "",
            password: "",
            commissions: record.commissions,
          }}
        >
          {({ handleSubmit, dirty, values, touched, errors }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <FormLabel>Details</FormLabel>
                    </Grid>
                    <Grid item xs={2}>
                      {allowEdit ? (
                        <>
                          <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => setAllowEdit(false)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAllowEdit(true);
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="businessName"
                        placeholder="Enter Business Name"
                        label="Business Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="firstName"
                        placeholder="Enter First Name"
                        label="First Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="lastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <PhoneNumberTextField name="phone" disabled={!allowEdit} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        name="email"
                        placeholder="Enter email"
                        label="Email"
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormFieldText
                        label="Password"
                        placeholder="Blank for current password"
                        name="password"
                        showIcon={false}
                        disabled={!allowEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <OutlinedSelectTextField
                        name="status"
                        label="Account Approval"
                        selections={[
                          { type: "Pending", value: "pending" },
                          { type: "Approved", value: "approved" },
                          { type: "Rejected", value: "rejected" },
                          { type: "Unclaim", value: "unclaim" },
                        ]}
                        disabled={!allowEdit}
                      />
                    </Grid>

                    <Grid item container xs={12} sm={12} spacing={2}>
                      <FieldArray name="commissions">
                        {({ remove, push }) => (
                          <>
                            <Grid item container xs={12} alignItems="center" columnGap={2}>
                              <FormLabel>Platform Commission(s)</FormLabel>
                              <Grid>
                                <Button
                                  sx={{
                                    borderRadius: "10px",
                                  }}
                                  variant="contained"
                                  disabled={!allowEdit}
                                  onClick={() =>
                                    push({
                                      id: uuidv4(),
                                      chargeType: "",
                                      chargeAmount: "",
                                    })
                                  }
                                >
                                  <Typography>+</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                            {values.commissions.map((comm, index) => (
                              <Grid item container spacing={2} key={comm.id} alignItems="center">
                                <Grid item xs={5}>
                                  <OutlinedSelectTextField
                                    name={`commissions[${index}].chargeType`}
                                    label="Charge Type"
                                    inputValue={comm.chargeType}
                                    selections={[
                                      { type: "Percentage", value: "percentage" },
                                      { type: "Fixed Amount", value: "value" },
                                    ]}
                                    errorMessage={
                                      errors.commissions && errors.commissions[index]
                                        ? errors.commissions[index].chargeType
                                        : undefined
                                    }
                                    customShowError={
                                      touched.commissions &&
                                      touched.commissions[index] &&
                                      errors.commissions &&
                                      errors.commissions[index] &&
                                      touched.commissions[index].chargeType &&
                                      typeof errors.commissions[index].chargeType === "string"
                                    }
                                    disabled={!allowEdit}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <FormFieldNumber
                                    name={`commissions[${index}].chargeAmount`}
                                    inputValue={comm.chargeAmount}
                                    placeholder="Enter Commission Percentage"
                                    label="Charge Amount"
                                    disabled={!allowEdit}
                                    errorMessage={
                                      errors.commissions && errors.commissions[index]
                                        ? errors.commissions[index].chargeAmount
                                        : undefined
                                    }
                                    customShowError={
                                      touched.commissions &&
                                      touched.commissions[index] &&
                                      errors.commissions &&
                                      errors.commissions[index] &&
                                      touched.commissions[index].chargeAmount &&
                                      typeof errors.commissions[index].chargeAmount === "string"
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <Button
                                    sx={{
                                      borderRadius: "10px",
                                    }}
                                    color="danger"
                                    variant="contained"
                                    disabled={!allowEdit}
                                    onClick={() => remove(index)}
                                  >
                                    <Typography>-</Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <RejectedFormField disabled={!allowEdit} />
                    </Grid>
                    {record.rejections?.length > 0 && (
                      <Grid item xs={12}>
                        <TableWrapper>
                          <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                            <TableHeader
                              headerTitle="Rejected Reasons"
                              headerCells={["Reason", "Rejected by"]}
                              nonSortableColumns={["Reason", "Rejected by"]}
                            />

                            <TableBody>
                              {record.rejections.map((rejection) => (
                                <TableRow record={record} key={uuidv4()}>
                                  <TableCell>{rejection.reason}</TableCell>
                                  <TableCell>{rejection.rejectedBy}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableWrapper>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </PaddedView>
    </>
  );
}

MerchantDetails.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNo: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    logo: PropTypes.string,
    rejections: PropTypes.arrayOf(
      PropTypes.shape({
        reason: PropTypes.string,
        rejectedBy: PropTypes.string,
      }),
    ),
    commissions: PropTypes.arrayOf(
      PropTypes.shape({
        chargeType: PropTypes.string,
        chargeAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    bank: PropTypes.shape({
      name: PropTypes.string,
      account: PropTypes.string,
      holderName: PropTypes.string,
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        location: PropTypes.string,
        status: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default MerchantDetails;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.service";

export const getPurchaseList = createAsyncThunk("employees/purchase/list", async (payload) => {
  const { startAt, endAt, types, q, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/purchases?${
      page ? "page=".concat(page) : "page=1"
    }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
      q ? "&q=".concat(q) : ""
    }${types ? "&types=".concat(types) : ""}`,
  );
  return response;
});

export const downloadInvoice = createAsyncThunk(
  "employees/purchase/download_invoice",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/purchases/download/${payload.id}`,
      payload,
      true,
    );
    return response;
  },
);

export const sendEmail = createAsyncThunk("employees/purchase/send_email", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/purchases/send_email/${payload.id}`,
    payload,
  );
  return response;
});

const purchaseSlice = createSlice({
  name: "purchase",
  initialState: {
    getPurchaseListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    downloadInvoiceObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    sendEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPurchaseList.pending]: (state) => {
      state.getPurchaseListObj.status = "pending";
    },
    [getPurchaseList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPurchaseListObj.status = "succeeded";
      state.getPurchaseListObj.data = data;
      state.getPurchaseListObj.successMessage = message;
    },
    [getPurchaseList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPurchaseListObj.status = "failed";
      state.getPurchaseListObj.errorMessage = message;
    },
    [downloadInvoice.pending]: (state) => {
      state.downloadInvoiceObj.status = "pending";
    },
    [downloadInvoice.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.downloadInvoiceObj.status = "succeeded";
      state.downloadInvoiceObj.data = data;
      state.downloadInvoiceObj.successMessage = message;
    },
    [downloadInvoice.rejected]: (state, action) => {
      const { message } = action.error;

      state.downloadInvoiceObj.status = "failed";
      state.downloadInvoiceObj.errorMessage = message;
    },
    [sendEmail.pending]: (state) => {
      state.sendEmailObj.status = "pending";
    },
    [sendEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.sendEmailObj.status = "succeeded";
      state.sendEmailObj.data = data;
      state.sendEmailObj.successMessage = message;
    },
    [sendEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.sendEmailObj.status = "failed";
      state.sendEmailObj.errorMessage = message;
    },
  },
});

export default purchaseSlice.reducer;

// state
export const purchaseSelector = (state) => state.purchase;

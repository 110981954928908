import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api.service";

export const getPlatformTransactionList = createAsyncThunk(
  "employees/platform_transaction/list",
  async (payload) => {
    const { startAt, endAt, businessIds, merchantIds, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/platform_transactions?${
        page ? "page=".concat(page) : "page=1"
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}${
        merchantIds ? "&merchantIds=".concat(merchantIds) : ""
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}`,
    );
    return response;
  },
);

export const getPlatformTransactionSummary = createAsyncThunk(
  "employees/platform_transaction/summary",
  async (payload) => {
    const { startAt, endAt, businessIds, merchantIds } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/platform_transactions/summary?${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}${
        merchantIds ? "&merchantIds=".concat(merchantIds) : ""
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}`,
    );
    return response;
  },
);

export const downloadPlatformStatement = createAsyncThunk(
  "employees/platform_transaction/download",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/platform_transactions/download?`,
      payload,
      true,
    );
    return response;
  },
);

const platformTransactionSlice = createSlice({
  name: "platformTransaction",
  initialState: {
    getPlatformTransactionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getPlatformTransactionSummaryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    downloadPlatformStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getPlatformTransactionList.pending]: (state) => {
      state.getPlatformTransactionListObj.status = "pending";
    },
    [getPlatformTransactionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPlatformTransactionListObj.status = "succeeded";
      state.getPlatformTransactionListObj.data = data;
      state.getPlatformTransactionListObj.successMessage = message;
    },
    [getPlatformTransactionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPlatformTransactionListObj.status = "failed";
      state.getPlatformTransactionListObj.errorMessage = message;
    },
    [getPlatformTransactionSummary.pending]: (state) => {
      state.getPlatformTransactionSummaryObj.status = "pending";
    },
    [getPlatformTransactionSummary.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getPlatformTransactionSummaryObj.status = "succeeded";
      state.getPlatformTransactionSummaryObj.data = data;
      state.getPlatformTransactionSummaryObj.successMessage = message;
    },
    [getPlatformTransactionSummary.rejected]: (state, action) => {
      const { message } = action.error;

      state.getPlatformTransactionSummaryObj.status = "failed";
      state.getPlatformTransactionSummaryObj.errorMessage = message;
    },
    [downloadPlatformStatement.pending]: (state) => {
      state.downloadPlatformStatementObj.status = "pending";
    },
    [downloadPlatformStatement.fulfilled]: (state, action) => {
      state.downloadPlatformStatementObj.status = "succeeded";
      state.downloadPlatformStatementObj.data = action.payload;
      state.downloadPlatformStatementObj.successMessage = "Success";
    },
    [downloadPlatformStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.downloadPlatformStatementObj.status = "failed";
      state.downloadPlatformStatementObj.errorMessage = message;
    },
  },
});

export default platformTransactionSlice.reducer;

// state
export const platformTransactionSelector = (state) => state.platformTransaction;

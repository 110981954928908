import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormikContext } from "formik";
import { Box, FormHelperText, useTheme } from "@mui/material";
import { format, isValid } from "date-fns";

export default function FormMonthPicker({
  name,
  isTransparent,
  width,
  placeholder,
  maxWidth,
  disabled,
  customOnChange,
  minMonth,
  maxMonth,
}) {
  const theme = useTheme();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (date) => {
    setFieldValue(name, isValid(date) ? format(date, "yyyy-MM-dd") : date);
    if (customOnChange) {
      customOnChange(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          "& .MuiTextField-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            width,
            maxWidth,
          },
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: isTransparent ? "transparent" : theme.palette.colors.text.white,
          },
        }}
      >
        <DatePicker
          views={["year", "month"]}
          value={values[name]}
          onChange={handleChange}
          InputProps={{
            error: showError || false,
          }}
          shouldDisableMonth={(date) => {
            const minDate = minMonth ? new Date(minMonth) : null;
            const maxDate = maxMonth ? new Date(maxMonth) : null;

            return (minDate && date < minDate) || (maxDate && date > maxDate);
          }}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              onBlur={() => setFieldTouched(name)}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder,
              }}
            />
          )}
        />
        {showError && <FormHelperText error>{errors[name]}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
}
FormMonthPicker.defaultProps = {
  width: null,
  isTransparent: false,
  placeholder: "dd/mm/yyyy",
  maxWidth: null,
  disabled: false,
  customOnChange: undefined,
  minMonth: undefined,
  maxMonth: undefined,
};

FormMonthPicker.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  isTransparent: PropTypes.bool,
  placeholder: PropTypes.string,
  maxWidth: PropTypes.string,
  customOnChange: PropTypes.func,
  minMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

import { TableCell, TableRow, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function PlatformTransactionRow({ record }) {
  const theme = useTheme();

  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{record.merchantBusinessName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.businessName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.description}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.itemTitle}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          sx={{
            color: record.amount.includes("-")
              ? theme.palette.colors.ui.error
              : theme.palette.colors.ui.success,
          }}
        >
          {record.amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.createdAt}</Typography>
      </TableCell>
    </TableRow>
  );
}

PlatformTransactionRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    merchantBusinessName: PropTypes.string,
    businessName: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    itemTitle: PropTypes.string,
    amount: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
};

export default PlatformTransactionRow;

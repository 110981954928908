import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import CtaButton from "../../../components/button/cta-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  downloadPlatformStatement,
  platformTransactionSelector,
} from "../../../services/platform-transaction/platform-transaction-slice.service";
import BusinessListAutoComplete from "../../merchant/fitness-class/components/business-list-autocomplete.component";
import MerchantListAutoComplete from "../../merchant/fitness-class/components/merchant-list-autocomplete.component";
import FormDatePicker from "./form-date-picker.component";
import FormMonthPicker from "./form-month-picker.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [startMonth, setStartMonth] = useState(() => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    return lastMonth;
  });

  const [endMonth, setEndMonth] = useState(() => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    return lastMonth;
  });
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { downloadPlatformStatementObj } = useSelector(platformTransactionSelector);

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  const handleDownloadStatement = async () => {
    const start = moment(startMonth).startOf("month");
    const end = moment(endMonth).endOf("month");

    // Validate that the difference is not more than 12 months
    if (end.diff(start, "months") > 12) {
      createSnackBar({ type: "error", message: "Date range cannot exceed 1 year" });
      return;
    }
    dispatch(
      downloadPlatformStatement({
        startAt: moment(startMonth).startOf("M").format("YYYY-MM-DD"),
        endAt: moment(endMonth).endOf("M").format("YYYY-MM-DD"),
      }),
    ).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        const url = window.URL.createObjectURL(new Blob([payload]));
        const link = document.createElement("a");
        link.href = url;
        const formatStartMonth = moment(startMonth).startOf("M").format("YYYY-MM");
        const formatEndMonth = moment(endMonth).endOf("M").format("YYYY-MM");

        link.setAttribute("download", `${formatStartMonth}_${formatEndMonth}_statement.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        createSnackBar({ type: "success", message: "Success" });
        setOpen(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({ type: "error", message: "Something went wrong" });
      }
    });
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.secondary,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <Grid item xs={6}>
        <PaddedView>
          <FormDatePicker
            name="startDate"
            disablePast={false}
            width="100%"
            placeholder="Start At"
          />
        </PaddedView>
      </Grid>
      <Grid item xs={6}>
        <PaddedView>
          <FormDatePicker name="endDate" disablePast={false} width="100%" placeholder="End At" />
        </PaddedView>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaddedView>
          <MerchantListAutoComplete name="merchantIds" placeholder="Merchants" multiple={true} />
        </PaddedView>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaddedView>
          <BusinessListAutoComplete
            name="businessIds"
            placeholder="Merchant Businesses"
            forSearch={true}
            multiple={true}
          />
        </PaddedView>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
          <Grid item xs={12} sm={3}>
            <PaddedView>
              <CtaButton onClickButton={() => setOpen(true)}>
                <Typography>Download Statement</Typography>
              </CtaButton>
            </PaddedView>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PaddedView>
              <FormSubmitButton type="submit">
                <Typography>Search</Typography>
              </FormSubmitButton>
            </PaddedView>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PaddedView>
              <BorderCtaButton onClickButton={handleClearForm}>
                <Typography sx={{ color: theme.palette.colors.brand.primary }}>Clear</Typography>
              </BorderCtaButton>
            </PaddedView>
          </Grid>
        </Grid>
      </Grid>

      {/* Modal for Selecting Month Range */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { borderRadius: "10px" }, // Set border radius
        }}
      >
        <DialogTitle>
          <b>Download Statement</b>
          <br />
          <span style={{ fontSize: "16px" }}>Select Month Range</span>
        </DialogTitle>
        <DialogContent>
          <FormMonthPicker
            name="startMonth"
            placeholder="Start Month"
            value={startMonth}
            customOnChange={setStartMonth}
            minMonth="2023-07-01"
            maxMonth={new Date(new Date().setMonth(new Date().getMonth() - 1))}
          />
          <Spacer />
          <FormMonthPicker
            name="endMonth"
            placeholder="End Month"
            value={endMonth}
            customOnChange={setEndMonth}
            minMonth="2023-07-01"
            maxMonth={new Date(new Date().setMonth(new Date().getMonth() - 1))}
          />
          <Spacer size="l" />
          <CtaButton
            disabled={downloadPlatformStatementObj.status === "pending"}
            onClickButton={handleDownloadStatement}
          >
            <Typography>Download</Typography>
          </CtaButton>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default FilterOptions;

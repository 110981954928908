import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { downloadInvoice, sendEmail } from "../../../services/purchase/purchase-slice.service";

function PurchaseRow({ record }) {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const handleDownloadInvoice = () => {
    dispatch(downloadInvoice({ id: record.id, type: record.type })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          const url = window.URL.createObjectURL(new Blob([payload]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          createSnackBar({
            message: "Download Successfully",
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };
  const handleSendEmail = () => {
    dispatch(sendEmail({ id: record.id, type: record.type })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell>
        <Typography variant="body2">{record.merchantBusinessName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.itemTitle}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.username}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.total}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{record.paidAt}</Typography>
      </TableCell>
      <TableCell>
        <Grid container>
          <IconButton onClick={handleDownloadInvoice}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={handleSendEmail}>
            <EmailIcon />
          </IconButton>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

PurchaseRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    itemTitle: PropTypes.string,
    merchantBusinessName: PropTypes.string,
    paidAt: PropTypes.string,
    total: PropTypes.string,
    username: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default PurchaseRow;

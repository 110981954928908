import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React from "react";
import BorderCtaButton from "../../../components/button/border-cta-button.component";
import FormFieldDebounceText from "../../../components/forms/form-field-debounce-text.component";
import FormMultiSelect from "../../../components/forms/form-multi-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import PaddedView from "../../../components/utils/padded-view.component";
import FormDatePicker from "./form-date-picker.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.secondary,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <Grid item xs={6}>
        <PaddedView>
          <FormDatePicker name="startAt" disablePast={false} width="100%" placeholder="Start At" />
        </PaddedView>
      </Grid>
      <Grid item xs={6}>
        <PaddedView>
          <FormDatePicker name="endAt" disablePast={false} width="100%" placeholder="End At" />
        </PaddedView>
      </Grid>
      <Grid item xs={6}>
        <PaddedView>
          <FormFieldDebounceText name="q" placeholder="Keyword" label="Search by User Details" />
        </PaddedView>
      </Grid>
      <Grid item xs={6}>
        <PaddedView>
          <FormMultiSelect
            name="types"
            label="Types"
            options={[
              { label: "Subscription", value: "subscription" },
              { label: "Package", value: "package" },
              { label: "Hourly Booking", value: "hourlyBooking" },
              { label: "Class Session Attendee", value: "classSessionAttendee" },
              { label: "Event Attendee", value: "eventAttendee" },
              { label: "Membership", value: "membership" },
              { label: "Sport Booking", value: "sportBooking" },
            ]}
          />
        </PaddedView>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
          <Grid item xs={12} sm={3}>
            <PaddedView>
              <FormSubmitButton type="submit">
                <Typography>Search</Typography>
              </FormSubmitButton>
            </PaddedView>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PaddedView>
              <BorderCtaButton onClickButton={handleClearForm}>
                <Typography sx={{ color: theme.palette.colors.brand.primary }}>Clear</Typography>
              </BorderCtaButton>
            </PaddedView>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FilterOptions;

import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PaddedView from "../../../components/utils/padded-view.component";
import Form from "../../../components/forms/form.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import LoadingSpinner from "../../../components/utils/backdrop-loading.component";
import BannerImageUpload from "../components/banners-image-upload.component";
import CustomizedSnackbars from "../../../components/notification/snackbar.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  updateBanner,
  updateBannerImage,
  checkBannerName,
} from "../../../services/marketing/banner/banner-slice.services";

import OutlinedSelectTextField from "../../../components/forms/outlined-select-text-field.component";
import BorderCtaButton from "../../../components/button/border-cta-button.component";

const validationSchema = Yup.object().shape({
  slug: Yup.string().required().lowercase().label("Slug"),
  active: Yup.string().required().label("Status"),
  image: Yup.string().required().label("Image"),
});

function BannerEditScreen() {
  const location = useLocation();
  const bannerId = location.state.id;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(location.state.imagePath);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarType, setSnackBarType] = useState("error");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isShowSnackBar, setIsShowSnackBar] = useState(false);
  const [onImageChange, setOnImageChange] = useState(false);
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();

  const showErrorSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("error");
    setIsShowSnackBar(true);
  };

  const showSuccessSnackBar = (message) => {
    setSnackBarMsg(message);
    setSnackBarType("success");
    setIsShowSnackBar(true);
  };

  const handleSlugChange = (values) => {
    if (values.length !== 0) {
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(
          () =>
            dispatch(checkBannerName({ slug: values })).then(({ meta, error, payload }) => {
              if (meta.requestStatus === "fulfilled") {
                showSuccessSnackBar(payload.message);
              }
              if (meta.requestStatus === "rejected") {
                showErrorSnackBar(error.message);
              }
            }),
          3000,
        ),
      );
    }
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    dispatch(updateBanner(values)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        showSuccessSnackBar(payload.message);
      }
      if (meta.requestStatus === "rejected") {
        showErrorSnackBar(error.message);
      }
      setIsLoading(false);
    });

    if (onImageChange) {
      dispatch(updateBannerImage(values)).then(({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setOnImageChange(false);
          showSuccessSnackBar(payload.message);
        }
        if (meta.requestStatus === "rejected") {
          showErrorSnackBar(error.message);
        }
        setIsLoading(false);
      });
    }
  };

  const renderEditBanner = () => (
    <Form
      validationSchema={validationSchema}
      initialValues={{
        bannerId,
        slug: location.state.slug,
        redirectUrl: location.state.redirectUrl,
        active: location.state.active,
        image: location.state.imagePath,
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={3} paddingTop={2}>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            sx={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 5 }}>
              <Box sx={{ width: "100%" }}>
                <FormFieldText
                  width="100%"
                  name="slug"
                  placeholder="Write the unique-identifier here"
                  label="Slug (Unique-Identifier)"
                  isSlug={true}
                  handleSlugChange={handleSlugChange}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid
            container
            sx={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 5 }}>
              <Box sx={{ width: "100%" }}>
                <FormFieldText
                  width="100%"
                  name="redirectUrl"
                  placeholder="Write the URL here"
                  label="URL"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid item xs={12} sm={3}>
            <OutlinedSelectTextField
              name="active"
              label="Status"
              selected={location.state.active}
              selections={[
                { type: "Inactive", value: false },
                { type: "Active", value: true },
              ]}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography>Banner Image</Typography>
          <Spacer />
          <BannerImageUpload
            name="image"
            filePreview={filePreview}
            setFilePreview={setFilePreview}
            onImageChange={onImageChange}
            setOnImageChange={setOnImageChange}
          />
        </Grid>

        <Grid item xs={12} sm={10}>
          <Grid container spacing={3}>
            <Grid item sm={2} xs={12}>
              <FormSubmitButton type="submit">
                <Typography>Save</Typography>
              </FormSubmitButton>
            </Grid>
            <Spacer size="s" position="left" />
            <Grid item sm={2} xs={12}>
              <BorderCtaButton onClickButton={() => navigate("/marketing/banners/list")}>
                <Typography sx={{ color: theme.palette.colors.brand.primary }}>Cancel</Typography>
              </BorderCtaButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );

  return (
    <Box>
      <CustomizedSnackbars
        isShow={isShowSnackBar}
        setShowSnackbar={setIsShowSnackBar}
        message={snackBarMsg}
        type={snackBarType}
      />
      <LoadingSpinner isLoading={isLoading} />
      <PaddedView>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Edit Banner</Typography>
          </Grid>
          {renderEditBanner()}
        </Grid>
      </PaddedView>
    </Box>
  );
}

export default BannerEditScreen;

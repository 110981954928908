import {
  Box,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Form from "../../../components/forms/form.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  getPurchaseList,
  purchaseSelector,
} from "../../../services/purchase/purchase-slice.service";
import FilterOptions from "../components/filter-options.component";
import PurchaseRow from "../components/purchase-row.component";
import TableLoader from "../loader/table-loader.component";

const validationSchema = Yup.object().shape({
  startAt: Yup.date().nullable().label("Start At").typeError("Invalid date"),
  endAt: Yup.date().label("End At").nullable(),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function PurchaseListScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();
  const { getPurchaseListObj } = useSelector(purchaseSelector);
  const [page, setPage] = useState(1);
  const columnMapping = {
    "Merchant Business Name": "merchantBusinessName",
    Username: "username",
    "Item Name": "itemTitle",
    "Total (RM)": "total",
    "Paid At": "paidAt",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onSearchPurchase = (values) => {
    setPage(1);
    let startAt = "";
    let endAt = "";
    if (values.startAt !== null && values.startAt !== "") {
      startAt = format(new Date(values.startAt), "yyyy-MM-dd");
    }
    if (values.endAt !== null) {
      endAt = format(new Date(values.endAt), "yyyy-MM-dd");
    }

    dispatch(
      getPurchaseList({
        startAt,
        endAt,
        q: values.q,
        types: values.types.join(","),
        page: 1,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    let startAt = "";
    let endAt = "";
    if (formRef.current.values.startAt !== null) {
      startAt = format(new Date(formRef.current.values.startAt), "yyyy-MM-dd");
    }
    if (formRef.current.values.endAt !== null) {
      endAt = format(new Date(formRef.current.values.endAt), "yyyy-MM-dd");
    }
    dispatch(
      getPurchaseList({
        startAt,
        endAt,
        q: formRef.current.values.q,
        types: formRef.current.values.types.join(","),
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onSearchPurchase(formRef.current.values);
  }, []);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getPurchaseListObj.data && getPurchaseListObj.status === "succeeded") {
      records = { ...getPurchaseListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    if (getPurchaseListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <>
          <Table>
            <TableHeader
              headerCells={[
                "Merchant Business Name",
                "Item Name",
                "Username",
                "Total (RM)",
                "Paid At",
                "Action",
              ]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
            />
            <TableBody>
              {records?.items.map((item) => (
                <PurchaseRow record={item} key={item.id} />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getPurchaseListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </>
      );
    }

    return <TableLoader />;
  };

  return (
    <PaddedView>
      <Form
        innerRef={formRef}
        onSubmit={onSearchPurchase}
        validationSchema={validationSchema}
        initialValues={{
          startAt: null,
          endAt: null,
          types: [],
          q: "",
        }}
      >
        <FilterOptions />
      </Form>
      <Spacer position="top" size="l" />
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Purchase List</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {renderContent()}
      </TableWrapper>
    </PaddedView>
  );
}
